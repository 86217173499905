<template>
	<div class="" style="background-color: #f4f3f4">
		<div class="Main width_1200 margin_auto flex">
			<div class="Main_l">
				<img src="https://zy.metaera.media/assets/images/1081.png" alt="" />
				<div class="Main_l_bot">
					<div>MetaEra 周刊｜6.5-6.11</div>
					<div>
						MetaEra周刊第114期，带您回顾一周热点、重要新闻、行情与合约数据、矿业信息、项目动态、技术进展等全行业资讯动态
					</div>
				</div>
			</div>
			<div class="MainList">
				<div class="MainItem">
					<div class="MainItemHead flex align_items_c">
						<div>区块链</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img src="https://zy.metaera.media/assets/images/3.png" alt="" />
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img src="https://zy.metaera.media/assets/images/3.png" alt="" />
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img src="https://zy.metaera.media/assets/images/3.png" alt="" />
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img src="https://zy.metaera.media/assets/images/3.png" alt="" />
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img src="https://zy.metaera.media/assets/images/3.png" alt="" />
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
				</div>
				<div class="MainItem">
					<div class="MainItemHead flex align_items_c">
						<div>区块链</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img src="https://zy.metaera.media/assets/images/3.png" alt="" />
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img src="https://zy.metaera.media/assets/images/3.png" alt="" />
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img src="https://zy.metaera.media/assets/images/3.png" alt="" />
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img src="https://zy.metaera.media/assets/images/3.png" alt="" />
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
					<div class="wzitem flex align_items_c">
						<img src="https://zy.metaera.media/assets/images/3.png" alt="" />
						<div class="wzitemL">
							<div class="ellipsis_one"
								>区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链区块链</div
							>
							<div>白话区块链</div>
						</div>
					</div>
				</div>
			</div>
			<div class="steps">
				<el-steps direction="vertical" :active="1">
					<el-step title="区块链"></el-step>
					<el-step title="比特币"></el-step>
					<el-step title="以太坊"></el-step>

					<el-step title="DeFi"></el-step>
					<el-step title="Polkadot"></el-step>
					<el-step title="NFT"></el-step>
					<el-step title="Layer2"></el-step>
					<el-step title="元宇宙"></el-step>
					<el-step title="扩容"></el-step>
					<el-step title="预言机"></el-step>
					<el-step title="稳定币"></el-step>
					<el-step title="加密经济学"></el-step>
				</el-steps>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
};
</script>

<style scoped>
/* Main */
.Main {
	padding-top: 36px;
}

.Main_l {
	width: 370px;
	height: 568px;
	border-radius: 20px;
}

.MainList {
	margin-left: 25px;
	padding-bottom: 20px;
}

.MainItem {
	width: 686px;
	background: #ffffff;
	border-radius: 18px 18px 18px 18px;
	opacity: 1;
	margin-top: 20px;
}

.MainItem:nth-child(1) {
	margin-top: 0;
}

.MainItemHead {
	width: 100%;
	height: 61px;
	border-bottom: 1px solid #efefef;
	border-radius: 20px 20px 0 0;
	position: relative;
}

.MainItemHead div {
	position: relative;
	margin-left: 20px;
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	padding-left: 20px;
}

.MainItemHead div::after {
	position: absolute;
	content: "";
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 3px;
	height: 18px;
	background: #0056ff;
	border-radius: 2px 2px 2px 2px;
	opacity: 0.85;
}

.wzitem {
	width: 94%;
	margin: 0 auto;
	height: 142px;
	border-bottom: 1px solid #efefef;
}

.wzitem img {
	width: 160px;
	height: 100px;
	border-radius: 5px;
}

.wzitemL {
	width: 70%;
	margin-left: 21px;
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.wzitemL div:nth-child(1) {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.wzitemL div:nth-child(2) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #a0a0a7;
}

.steps {
	width: 100px;
	margin-left: 10px;
}
.stepsTime {
	font-size: 12px;
	font-family: DINPro-Regular, DINPro;
	font-weight: 400;
	color: #000000;
}
.stepsVal {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000 !important;
	margin-top: 5px;
}
</style>
<style></style>
